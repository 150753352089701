import * as Sentry from "@sentry/remix";
import { RemixBrowser, useLocation, useMatches } from "@remix-run/react";
import { startTransition, StrictMode, useEffect } from "react";
import { hydrateRoot } from "react-dom/client";

// Sentry.init({
//     dsn: "https://06576dbfc7a73448feb763fbeab204b9@o4506271768772608.ingest.sentry.io/4506383385952256",
//     tracesSampleRate: 1,
//     replaysSessionSampleRate: 0.1,
//     replaysOnErrorSampleRate: 1,

//     integrations: [new Sentry.BrowserTracing({
//         routingInstrumentation: Sentry.remixRouterInstrumentation(useEffect, useLocation, useMatches)
//     }), new Sentry.Replay()]
// })

startTransition(() => {
  hydrateRoot(
    document,
    <StrictMode>
      <RemixBrowser />
    </StrictMode>,
  );
});